@import "~react-virtualized/styles";

body {
  font-family: "Roboto", "Microsoft YaHei", "Meiryo", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--background-image);
  background-attachment: fixed;
  color-scheme: only light;
}
/* @media (min-width: 1025px) { */
/* background-attachment breaks on iOS: https://caniuse.com/background-attachment */
body {
  background: transparent;
}
body::before {
  content: "";
  display: block;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  background-image: var(--background-image);
}
/* } */
.koromo {
  --background-image: url(../assets/img/koromo.jpg);
}
.achiga {
  --background-image: url(../assets/img/achiga.jpg);
}
.yuuki {
  --background-image: url(../assets/img/yuuki.jpg);
}
#root {
  overflow: hidden;
}
.text-right {
  text-align: right;
}
.ReactVirtualized__Table__row.even {
  background-color: rgba(0, 0, 0, 0.05);
}
.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  border-top: 1px solid #dee2e6;
}
.ReactVirtualized__Table__headerRow span {
  display: block;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.ReactVirtualized__Table__row.loading {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}
.ReactVirtualized__Table__row.loading:nth-of-type(odd) {
  animation-delay: -0.75s;
}
svg.recharts-surface {
  overflow: visible;
}
.recharts-label,
.recharts-label-list {
  pointer-events: none;
  user-select: none;
}
.recharts-pie-label-text {
  font-weight: bold;
  transform: translateY(5px);
}
.recharts-tooltip-wrapper {
  z-index: 1;
}
.recharts-pie.selectable {
  transition: transform 0.3s;
  transform: scale(0.95);
  transform-origin: center;
}
.recharts-pie.selectable.with-active,
.recharts-pie.selectable:hover {
  transform: scale(1);
}
.recharts-pie-sector .recharts-sector.selectable {
  cursor: pointer;
  transition: opacity 0.3s;
}
.recharts-pie.with-active .recharts-pie-sector .recharts-sector {
  opacity: 0.1;
}
.recharts-pie.with-active .recharts-pie-sector .recharts-sector.active {
  opacity: 1;
}
.recharts-pie-sector .recharts-sector.selectable:hover {
  opacity: 0.8;
}
